<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>

<style lang="scss">
    .app {
        width: 100%;
        max-width: 1024px;
        height: 100%;
        max-height: 600px;
        margin: 0 auto;
    }

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Inter', sans-serif;
        outline: none;
    }
</style>
